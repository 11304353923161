<template>
  <type-page :config="sysList" />
</template>

<script>
import { sysList } from '@/data_conf/sys'
import TypePage from '@/components/datainfo/TypePage.vue'

export default {
  components: {
    TypePage
  },
  data () {
    return {
      sysList: sysList({
        openConf: {
          position: 'left',
          isPop: true
        }
      })
    }
  }
}
</script>
